import React, { useMemo } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import {
  howItWorksSections,
  howItWorksTitle,
  howItWorksIntro,
} from "../page-info/how-it-works-info"
import IllustrationList from "../UI/IllustrationList"

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 15,
  },
}))

const HomeHowItWorks = () => {
  const classes = useStyles()

  const illustrationItems = useMemo(
    () =>
      howItWorksSections.map((item, index) => ({
        sectionId: item.id,
        title: item.title,
        image: item.image,
        desc: item.summary,
        more: item.more,
      })),
    []
  )

  return (
    <div>
      <Typography
        component="h2"
        variant="h4"
        className={classes.title}
        gutterBottom
      >
        {howItWorksTitle}
      </Typography>
      <Typography gutterBottom>{howItWorksIntro}</Typography>
      <IllustrationList
        path="/how-it-works"
        pageId="howitworks"
        items={illustrationItems}
      />
    </div>
  )
}
export default HomeHowItWorks
