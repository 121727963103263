import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import Loadable from "@loadable/component"

const SpeedTest = Loadable(() => import("./SpeedTest"), {
  fallback: <div>Loading speed test...</div>,
})

const useStyles = makeStyles(theme => ({
  testWebsite: {
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}))

const SpeedTestContainer = () => {
  const classes = useStyles()

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Typography variant="overline" className={classes.testWebsite}>
          See how fast your website loads:
        </Typography>
      </div>

      <SpeedTest />
    </div>
  )
}

export default SpeedTestContainer
