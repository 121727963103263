import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Typography } from "@material-ui/core"
import HomeBanner from "../components/home-sections/HomeBanner"
import HomeHowItWorks from "../components/home-sections/HomeHowItWorks"
import HomeAnyTask from "../components/home-sections/HomeAnyTask"
import HomeBenefits from "../components/home-sections/HomeBenefits"
import HomeConversion from "../components/home-sections/HomeConversion"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Layout titleId="home-title">
      <SEO />
      <Container>
        <Typography id="home-title" component="h1" variant="srOnly">
          {site.siteMetadata.title}
        </Typography>
        <HomeBanner />
        <HomeBenefits />
        <HomeConversion />
        <HomeHowItWorks />
        <HomeAnyTask />
      </Container>
    </Layout>
  )
}

export default IndexPage
