import React from "react"
import { Typography } from "@material-ui/core"
import Analysis from "../../images/Pace_analysis.svg"
import Review from "../../images/Pace_review.svg"
import Perform from "../../images/Pace_perform.svg"

export const howItWorksTitle = "How it works"

export const howItWorksIntro = (
  <>
    Paceology is a website optimization service that addresses a significant
    erosion in website traffic, sales, and overall conversion that is mostly
    overlooked: Website loading performance, especially on mobile networks.
  </>
)

export const howItWorksSections = [
  {
    id: "automated-review",
    title: "Automated Review",
    image: Analysis,
    more: "More about initial tests",
    summary:
      "We run initial automated speed tests and review longer-term stats to diagnosis the issue.",
    content: (
      <>
        <Typography paragraph>
          We run initial automated speed tests and review longer-term stats to
          diagnosis the issue. Here we can at least outline the general issues
          to see what quick gains can be made and start to estimate how much
          work will be involved to make these changes.
        </Typography>
      </>
    ),
  },
  {
    id: "technical-plan",
    title: "Technical Plan",
    image: Review,
    more: "More about our initial review",
    summary:
      "We manually review your code and provide a written technical plan.",
    content: (
      <>
        <Typography paragraph>
          We manually review your site and server and, using the initial review
          as a guide, walk through your code to understand how these updates can
          be implemented.
        </Typography>
        <Typography paragraph>
          Unlike the initial automated review, we now take the time to explore
          the true extent of each alert and the potential impact of each
          proposed change. Of course, simply optimizing loading speed is not
          enough, your site has it's own feature requirements that must be
          maintained.
        </Typography>
        <Typography paragraph>
          At the end of this process, we produce a report that will outline each
          of the necessary tasks and potential challenges.
        </Typography>
        <Typography paragraph>
          You can either take this plan and use your own technical team to
          implement the necessary changes or allow us to complete them for you.
        </Typography>
      </>
    ),
  },
  {
    id: "implementation",
    title: "Implementation",
    image: Perform,
    more: "More about implementation",
    summary:
      "We implement this plan or provide support for your team to perform the necessary upgrades.",
    content: (
      <>
        <Typography paragraph>
          If you have decided you would like us to implement the recommendations
          in our custom technical plan, we will begin our upgrades in a safe
          redundant environment.
        </Typography>
        <Typography paragraph>
          In fact, nothing will be implemented to a live server until after
          rigorous testing has been completed, and we receive official approval.
        </Typography>
        <Typography paragraph>
          We will objectively show you each upgrade and its impact on reported
          results against our original benchmark report.
        </Typography>
      </>
    ),
  },
]
