import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SpeedTestContainer from "./SpeedTestContainer"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  inputContainer: {
    flexGrow: 2,
  },

  illustration: {
    width: "100%",
    maxWidth: 435,
    maxHeight: 435,
  },
  headline: {
    marginLeft: 25,
    marginTop: 45,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
}))

const HomeBanner = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      Pacer: file(relativePath: { eq: "Pace_Illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 435, maxHeight: 435) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      <div className={classes.illustration}>
        <Img
          fluid={data.Pacer.childImageSharp.fluid}
          loading="eager"
          alt="Paceology web speed optimization"
        />
      </div>
      <div className={classes.headline}>
        <Typography component='p' variant="h2">
          53% of mobile visitors leave a site that takes longer than 3 seconds
          to load{" "}
          <span style={{ fontSize: '0.7em' }}>
            -<span style={{ color: "#4f80ed" }}>G</span>
            <span style={{ color: "#e2163b" }}>o</span>
            <span style={{ color: "#f5c329" }}>o</span>
            <span style={{ color: "#4f80ed" }}>g</span>
            <span style={{ color: "#25be5a" }}>l</span>
            <span style={{ color: "#e2163b" }}>e</span>
          </span>
        </Typography>
        <SpeedTestContainer />
      </div>
    </div>
  )
}
export default HomeBanner
