import React, { useMemo } from "react"
import { Button, makeStyles, Typography } from "@material-ui/core"
import {
  benefitsSections,
  benefitsTitle,
  benefitsIntro,
} from "../page-info/benefits-info"
import { Link } from "gatsby"
import IllustrationList from "../UI/IllustrationList"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-20px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  title: {
    marginTop: 15,
  },
}))

const HomeBenefits = props => {
  const classes = useStyles()

  const illustrationItems = useMemo(
    () =>
      benefitsSections.map((item, index) => ({
        sectionId: item.id,
        title: item.title,
        image: item.image,
        desc: item.summary,
        more: item.more,
      })),
    []
  )

  return (
    <div>
      <Typography
        component="h2"
        variant="h4"
        className={classes.title}
        gutterBottom
      >
        {benefitsTitle}
      </Typography>
      <Typography gutterBottom>{benefitsIntro}</Typography>
      <IllustrationList
        path="/benefits"
        pageId="benefits"
        items={illustrationItems}
      />
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button
          component={Link}
          to="/estimate/"
          variant="contained"
          color="primary"
        >
          Start your customized plan
        </Button>
      </div>
    </div>
  )
}
export default HomeBenefits
