import React from "react"
import { makeStyles } from "@material-ui/core"
import IllustrationBox from "./IllustrationBox"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
	justifyContent: "space-between",
	margin: 0,
	padding: 0,
	marginTop: "-20px",
	listStyle: 'none',
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}))

const IllustrationList = props => {
  const classes = useStyles(props)
  const { path, pageId, items } = props

  const list = items.map((item, index) => (
    <IllustrationBox
      key={item.id}
      path={path}
	  pageId={pageId}
	  index={index + 1}
      sectionId={item.sectionId}
      title={item.title}
      image={item.image}
      desc={item.desc}
      more={item.more}
    />
  ))

  return (
    <ol className={classes.root}>
      {list}
    </ol>
  )
}

export default IllustrationList
