import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import WalmartChart from "../../images/WalmartChart.svg"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    padding: theme.spacing(3, 3),
  },
  walmartChart: {
    maxWidth: 800,
    width: "100%",
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 800,
    },
  },
  columns: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  textColumn: {
    // marginTop: 60
  },
  li: {
    marginBottom: 15,
    fontSize: "16px",
  },
}))

const HomeConversion = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h4">
        The direct connection between load time and conversion
      </Typography>

      <div className={classes.columns}>
        <div className={classes.walmartChart}>
          <img
            src={WalmartChart}
            loading="lazy"
            alt="Sharp decline in conversion rate as average load time increases from 1 to 4 seconds ~ Source: Walmart"
          />
        </div>
        <div className={classes.textColumn}>
          <Typography paragraph>
            Google now claims that you will lose over half of your traffic if
            your site takes longer than 3 seconds to load and that for each
            fraction of a second you gain, you can expect a corresponding rise
            in conversion and, therefore, revenue or audience share (as
            illustrated in this study by Walmart).
          </Typography>

          <Typography paragraph>
            Load speed is often easy to fix and one of the few metrics that are
            objective and within the website owners' control.
            <strong>
              Surprisingly, most companies and organizations don't know their
              website load-time.
            </strong>
          </Typography>
        </div>
      </div>
      <Typography component="p" variant="h6">
        The real problem of slow load-time is often masked by the following
        reasons:
      </Typography>

      <ol>
        <li className={classes.li}>
          Many website owners forget or don't understand their website is cached
          and therefore{" "}
          <strong>
            do not experience what a first-time load feels like to new
            customers.
          </strong>
        </li>
        <li className={classes.li}>
          Many website owners look at their site within a{" "}
          <strong>highspeed home or office connection</strong> and don't notice
          the effects of a <strong>slower mobile network.</strong>
        </li>
        <li className={classes.li}>
          Many website owners don't know that more than{" "}
          <strong>half of their traffic is now via mobile devices.</strong>
        </li>
        <li className={classes.li}>
          Many website design companies, SEO experts, in-house marketing, and
          IT, do not have the technical knowledge, awareness, or direction.
        </li>
      </ol>
    </div>
  )
}
export default HomeConversion
