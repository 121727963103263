import React, { useRef, useCallback } from "react"
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    marginTop: 25,
  },
  card: {
    minWidth: 200,
    maxWidth: 400,
  },
  media: {
    height: 140,
  },
}))

const IllustrationBox = props => {
  const classes = useStyles(props)
  const to = props.path + "#" + props.sectionId
  const id = props.pageId + "-" + props.sectionId
  const titleId = id + "-title"
  const descId = id + "-desc"

  const moreButton = useRef()
  const clickMore = useCallback(() => moreButton.current.click(), [])

  return (
    <div
      className={classes.root}
      role="listitem"
      aria-labelledby={titleId}
      aria-describedby={descId}
    >
      <Card className={classes.card}>
        <CardActionArea component='div' role={null} tabIndex={null} onClick={clickMore}>
          <CardMedia
            className={classes.media}
            image={props.image}
            title={props.title}
          />
          <CardContent>
            <Typography
              id={titleId}
              aria-label={props.title}
              gutterBottom
              component="h3"
              variant="h5"
            >
              {props.index}) {props.title}
            </Typography>
            <Typography
              id={descId}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {props.desc}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            ref={moreButton}
            component={Link}
            to={to}
            size="small"
            color="primary"
          >
            {props.more}
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
export default IllustrationBox
