import React from "react"
import { Button, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import PaceologyMachine from "../../images/paceology_machine.svg"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    padding: theme.spacing(3, 3),
  },
  walmartChart: {
    maxWidth: 800,
    minWidth: 800,
    width: "100%",
  },
  columns: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  li: {
    marginBottom: 15,
    fontSize: "16px",
  },
  layout: {
    display: "flex",
  },
  paceologyMachine: {
    display: "none",
    width: 320,
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}))

const HomeAnyTask = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h4" gutterBottom>
        We can take care of any optimization task on any system
      </Typography>

      <div className={classes.columns}>
        <div>
          <Typography paragraph>
            We work well with existing IT departments, SEO companies, website
            development companies, or individual operators to provide this
            focused optimization service.
          </Typography>

          <div className={classes.layout}>
            <div>
              <Typography paragraph>
                We are happy to pass an optimization plan to your existing
                technical staff or perform the updates for you so your
                developers can continue to focus on what they do best.
              </Typography>

              <Typography component='p' variant="h6">
                {" "}
                We can handle any optimization task, including:
              </Typography>

              <ul>
                <li className={classes.li}>
                  removing, minifying, reducing, deferring JavaScript and CSS
                </li>
                <li className={classes.li}>setting server cache policy</li>
                <li className={classes.li}>affecting server response time</li>
                <li className={classes.li}>
                  setting next-gen image compression (up to 40% faster image
                  loading)
                </li>
                <li className={classes.li}>
                  setting up content delivery networks
                </li>
                <li className={classes.li}>reducing critical chain requests</li>
                <li className={classes.li}>
                  quickly showing text before fonts load
                </li>
                <li className={classes.li}>and much more</li>
              </ul>

              <Typography paragraph>
                We will handle everything you need to make your site appear to
                load more quickly (which helps in conversion) and actually load
                quicker, which of course, is the ultimate goal.
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.paceologyMachine}>
          <img
            src={PaceologyMachine}
            width={320}
            height={498}
            loading="lazy"
            alt="Paceology custom implementation"
          />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          component={Link}
          to="/estimate/"
          variant="contained"
          color="primary"
        >
          Start your customized plan
        </Button>
      </div>
    </div>
  )
}

export default HomeAnyTask
